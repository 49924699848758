import { LoadingOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Form, Input, Row } from 'antd';
import React, { useState } from 'react';
import AccountApiService from '../../api/AccountApiService';
import Routes from '../../consts/Routes';
import HistoryUtil from '../../utils/HistoryUtil';
import LoginLayout from './LoginLayout';
import useUrlQuery from '../../hooks/useUrlQuery';

const FormItem = Form.Item;

interface ResendConfirmationEmailPageProps {
  mobileview?: string;
}

const ResendConfirmationEmailPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [resendSuccess, setResendSuccess] = useState(false);
  const [urlQuery] = useUrlQuery<ResendConfirmationEmailPageProps>();

  let alert: JSX.Element | null = null;

  if (error !== null) {
    let message = 'An error occurred while trying to confirm email.';
    if (error.message) {
      message = error.message;
    }
    alert = (
      <Alert message="Error" description={message} type="error" showIcon={true} style={{ marginBottom: '12px' }} />
    );
  }
  if (resendSuccess) {
    const message = 'Confirmation email sent.';
    alert = (
      <Alert message="Success" description={message} type="success" showIcon={true} style={{ marginBottom: '12px' }} />
    );
  }

  const handleSubmit = (values: any) => {
    setLoading(true);

    AccountApiService.resendConfirmEmail(values.email).then(
      () => {
        setLoading(false);
        setResendSuccess(true);
        setError(false);
      },
      (error) => {
        setLoading(false);
        setError(error);
        setResendSuccess(false);
      }
    );
  };

  return (
    <LoginLayout
      cardTitle="Resend Confirmation Email"
      prompt="Please enter the email associated with your new account."
    >
      <Row justify="center">
        <Col xs={24} sm={18}>
          {alert}
          <Form layout="vertical" onFinish={handleSubmit} requiredMark={false}>
            <FormItem
              name="email"
              label="Email Address"
              rules={[{ required: true, message: 'A valid Email Address is required', type: 'email' }]}
            >
              <Input placeholder="Email" />
            </FormItem>
            <FormItem>
              <Button type="primary" htmlType="submit" size="large" disabled={loading} shape="round" block>
                {loading ? (
                  <span>
                    <LoadingOutlined /> Processing...
                  </span>
                ) : (
                  <span>Send Email</span>
                )}
              </Button>
            </FormItem>
            <FormItem>
              <Button
                onClick={() => HistoryUtil.push(Routes.generate(Routes.LOGIN, {}, { mobileview: urlQuery.mobileview }))}
                htmlType="button"
                size="large"
                disabled={loading}
                shape="round"
                block
                ghost
              >
                Back to Login
              </Button>
            </FormItem>
          </Form>
        </Col>
      </Row>
    </LoginLayout>
  );
};

export default ResendConfirmationEmailPage;
