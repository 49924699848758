// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface EducationDocumentDTO { 
    id: number;
    title: string | null;
    description: string | null;
    videoUrl: string | null;
    pdfUrl: string | null;
    educationPageId: number | null;
    documentOrder: number;
}
const EducationDocumentDTO = {
    create: (initValues?: Partial<EducationDocumentDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            id: 0,
            title: null,
            description: null,
            videoUrl: null,
            pdfUrl: null,
            educationPageId: null,
            documentOrder: 0,
        },
        initValues);
    }
};

export default EducationDocumentDTO;