import { Button, Col, Layout, List, Modal, notification, Row, Spin, Tabs, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import EducationApiService from '../../api/EducationApiService';
import PageTitle from '../../components/shared/PageTitle';
import EducationPageTabs from '../../consts/EducationPage';
import useUrlQuery from '../../hooks/useUrlQuery';
import EducationDocumentDTO from '../../models/EducationDocumentDTO';
import Renderers from '../../utils/Renderers';

const { TabPane } = Tabs;

const ALL_DOCUMENTS_KEY = -1;

interface EducationPageRouteProps {
  mobileview?: string;
}

const EducationPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
  const [selectedVideo, setSelectedVideo] = useState<string>();
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [documents, setDocuments] = useState<EducationDocumentDTO[]>([]);
  const [routeQuery] = useUrlQuery<EducationPageRouteProps>();

  useEffect(() => {
    setLoading(true);
    loadDocuments(EducationPageTabs.BEFORE_CARE);
  }, []);

  const tabText: { educationPageId: number; content: React.ReactNode }[] = [
    {
      educationPageId: EducationPageTabs.BEFORE_CARE,
      content: (
        <p>
          Once you submit a Provider Nomination Form, as part of the Pave the Way® program, a ClaimDOC Member Advocate
          will reach out to your healthcare providers to ensure they have all the necessary information regarding your
          insurance plan.
        </p>
      ),
    },
    {
      educationPageId: EducationPageTabs.DURING_CARE,
      content: (
        <p>
          At your appointment, present your medical plan ID card and expect to receive care. You can confidently say, “I
          have benefits and my plan will pay!” A Member Advocate is available to answer any questions and educate your
          provider.
        </p>
      ),
    },
    {
      educationPageId: EducationPageTabs.AFTER_CARE,
      content: (
        <p>
          Always remember you are only responsible for the Patient Responsibility on the Explanation of Benefits (EOB)
          sent to you by the plan administrator. ClaimDOC is happy to assist you with interpreting any invoice you
          receive from a provider.
        </p>
      ),
    },
    {
      educationPageId: ALL_DOCUMENTS_KEY,
      content: <></>,
    },
  ];

  // Select Featured Content by supplying an id from the database
  const featuredContent: EducationDocumentDTO = EducationDocumentDTO.create({ ...documents.find((d) => d.id === 20) });
  // Override the title / description if needed...
  featuredContent.title = 'What is RBP?';
  //featuredContent.description = '';

  const businessContacts: { title: string; phone: string; email?: string; url?: string }[] = [
    {
      title: 'Member Advocacy',
      phone: '(888) 330-7295',
      email: 'membersupport@claim-doc.com',
    },
    {
      title: 'Provider Nomination',
      phone: '(888) 330-7295',
    },
    {
      title: 'Balance Bills',
      phone: '(888) 330-7295',
      email: 'balancebills@claim-doc.com',
    },
  ];

  const businessContent = {
    hours: 'Monday - Friday 7am - 6pm CST',
    contacts: businessContacts,
  };

  const loadDocuments = (tab: any) => {
    setLoadingDocuments(true);
    EducationApiService.getDocuments(tab)
      .then((res) => {
        setDocuments(res);
      })
      .catch((err) => {
        notification.error({ message: err.message });
      })
      .finally(() => {
        setLoading(false);
        setLoadingDocuments(false);
      });
  };

  const introRender = () => {
    return (
      <>
        <Row>
          <PageTitle title="Education" style={{ marginBottom: '10px' }} />
          <Typography.Title level={5}>
            Your open-access health plan means you have the freedom to choose any provider you wish, whether it is a
            primary care physician, specialist, clinic or facility. The ClaimDOC team is here to support you as you
            navigate the features of your health plan.
          </Typography.Title>
        </Row>
        <Row className="education-margin-top">
          <div>
            <div aria-label="Click to play video" className="education-featured-image-container">
              {featuredContent?.videoUrl && (
                <Tooltip title="Click to play video">
                  <img
                    src="../../../../reference-based-pricing.png"
                    aria-label={`View ${featuredContent.title} Video`}
                    onClick={() => {
                      setSelectedVideo(featuredContent?.videoUrl || '');
                      setShowVideo(true);
                    }}
                    className="education-featured-image"
                  />
                </Tooltip>
              )}
            </div>
            <div className="education-featured-text-container">
              <Typography.Title level={3}>{featuredContent?.title}</Typography.Title>
              <Typography.Text>{featuredContent?.description}</Typography.Text>
              <br />
              <Row>
                <Button
                  className="education-margin-top"
                  style={{ marginLeft: 'auto', marginRight: 'auto' }}
                  aria-label={`View ${featuredContent.title} PDF`}
                  type="primary"
                  shape="round"
                  onClick={() => {
                    if (routeQuery.mobileview) {
                      window.location.replace(featuredContent?.pdfUrl || '');
                    } else {
                      window.open(featuredContent?.pdfUrl || '');
                    }
                  }}
                >
                  PDF
                </Button>
              </Row>
            </div>
          </div>
        </Row>
      </>
    );
  };

  const businessRender = () => {
    return (
      <>
        <h2>Hours of Operation</h2>
        <h4>{businessContent.hours}</h4>
        {businessContent.contacts.map((c, i) => (
          <div key={i}>
            <br />
            <h2>{c.title}</h2>
            <h4>{Renderers.phoneNumberLink(c.phone)}</h4>
            {c.email && (
              <Button shape="round" ghost aria-label={`Email ${c.title}.`} href={'mailto:' + c.email} rel="noreferrer">
                Email
              </Button>
            )}
          </div>
        ))}
      </>
    );
  };

  const documentRender = (key: number) => {
    let docs = documents;

    if (key !== ALL_DOCUMENTS_KEY) {
      docs = docs.filter((d) => d.educationPageId === key);
    }

    return (
      <div>
        {tabText.find((t) => t.educationPageId === key)?.content}
        <List
          loading={loadingDocuments}
          className="education-margin-top"
          dataSource={docs}
          renderItem={(item, index) => (
            <List.Item
              key={item.id}
              className={index % 2 !== 1 ? 'education-secondary-background' : undefined}
              actions={[
                <>
                  {item.videoUrl && (
                    <Button
                      aria-label={`View ${item.title} Video`}
                      key={index}
                      shape="round"
                      hidden={!item.videoUrl}
                      onClick={() => {
                        setSelectedVideo(item.videoUrl || '');
                        setShowVideo(true);
                      }}
                    >
                      Video
                    </Button>
                  )}
                </>,
                <>
                  {item.pdfUrl && (
                    <Button
                      aria-label={`View ${item.title} PDF`}
                      key={index}
                      shape="round"
                      hidden={!item.pdfUrl}
                      onClick={() => {
                        if (routeQuery.mobileview) {
                          window.location.replace(item.pdfUrl || '');
                        } else {
                          window.open(item.pdfUrl || '');
                        }
                      }}
                    >
                      PDF
                    </Button>
                  )}
                </>,
              ]}
            >
              <List.Item.Meta title={item.title} className="education-margin-left" />
            </List.Item>
          )}
        />
      </div>
    );
  };

  const handleTabChange = (tab: any) => {
    loadDocuments(tab);
  };

  return (
    <Spin spinning={loading}>
      <Layout>
        <Layout.Content className="education-secondary-background">
          <Modal
            visible={showVideo}
            okText="Close"
            onOk={() => setShowVideo(false)}
            cancelButtonProps={{ hidden: true }}
            closable={false}
            destroyOnClose
          >
            <div className="education-video-container">
              <iframe
                className="education-video"
                title="ClaimDOC Support Video"
                frameBorder={0}
                allowFullScreen
                allow="autoplay"
                src={selectedVideo}
              ></iframe>
            </div>
          </Modal>
          <Row>
            <Col
              xs={{ span: 24, order: 1 }}
              lg={{ span: 16, order: 1 }}
              xl={{ span: 14, order: 1 }}
              className="content-padding content-padding-xl education-main-background"
            >
              <div className="education-margin-bottom">{introRender()}</div>
              <Tabs
                onChange={handleTabChange}
                renderTabBar={(props, TabNavList) => <TabNavList {...props} mobile={false} />}
              >
                <TabPane tab={<div className="education-tab">Before Care</div>} key={EducationPageTabs.BEFORE_CARE}>
                  {documentRender(EducationPageTabs.BEFORE_CARE)}
                </TabPane>
                <TabPane tab={<div className="education-tab">During Care</div>} key={EducationPageTabs.DURING_CARE}>
                  {documentRender(EducationPageTabs.DURING_CARE)}
                </TabPane>
                <TabPane tab={<div className="education-tab">After Care</div>} key={EducationPageTabs.AFTER_CARE}>
                  {documentRender(EducationPageTabs.AFTER_CARE)}
                </TabPane>
                <TabPane tab={<div className="education-tab">All Documents</div>} key={ALL_DOCUMENTS_KEY}>
                  {documentRender(ALL_DOCUMENTS_KEY)}
                </TabPane>
              </Tabs>
            </Col>
            <Col
              xs={{ span: 24, order: 2 }}
              lg={{ span: 8, order: 2 }}
              xl={{ span: 10, order: 2 }}
              className="content-padding content-padding-xl education-secondary-background"
            >
              {businessRender()}
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </Spin>
  );
};

export default EducationPage;
