import { LoadingOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Form, Input, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AccountApiService from '../../api/AccountApiService';
import PageTitle from '../../components/shared/PageTitle';
import Routes from '../../consts/Routes';
import useUrlQuery from '../../hooks/useUrlQuery';
import ResultDTO from '../../models/ResultDTO';
import LoginLayout from './LoginLayout';

const FormItem = Form.Item;

interface ResetPasswordProps {
  code?: string;
  Id?: string; // Case sensitive
  mobileview?: string;
}

const ResetPasswordPage = () => {
  const [loading, setLoading] = useState(true);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [error, setError] = useState<any>(null);
  const [linkVerified, setLinkVerified] = useState<boolean | null>(null);
  const [userId, setUserId] = useState<number | undefined>(undefined);
  const [code, setCode] = useState<string | undefined>(undefined);

  const [urlQuery] = useUrlQuery<ResetPasswordProps>();

  useEffect(() => {
    let userId: number | null = null;

    if (urlQuery.Id) {
      userId = parseInt(urlQuery.Id);
    }

    setUserId(userId && !isNaN(userId) ? userId : 0);
    setCode(urlQuery.code);
  }, [urlQuery]);

  useEffect(() => {
    if (userId && code) {
      setLoading(true);

      AccountApiService.canResetPassword(userId, code).then(
        () => {
          setLoading(false);
          setError(null);
          setLinkVerified(true);
        },
        (error: any) => {
          setLoading(false);
          setError(error);
          setLinkVerified(false);
        }
      );
    }
  }, [userId, code]);

  const handleSubmit = (model: any) => {
    setLoading(true);

    if (!userId || !code) {
      setLoading(false);
      setError({ message: 'Invalid link.' });
      setLinkVerified(false);
      return;
    }

    model.url = location.href;
    AccountApiService.resetPassword(userId, code, model).then(
      (response) => {
        setLoading(false);
        if (response.succeeded) {
          setError(null);
          setResetSuccess(true);
        } else {
          // Handle the error according to the response
          setError({ message: response.errors.join('\n') });
          setResetSuccess(false);
        }
      },
      (error) => {
        setLoading(false);
        setError(error);
        setResetSuccess(false);
      }
    );
  };

  const validatePassword = (e: any) => {
    if (e.target.value !== null && e.target.value !== '') {
      AccountApiService.validatePassword(e.target.value).then((response: ResultDTO | null) => {
        if (response?.succeeded) {
          setError(null);
        } else {
          setError({ message: response?.errors.join('\n') });
        }
      });
    }
  };

  let alert: JSX.Element | null = null;
  if (error !== null) {
    let message = 'Invalid link.';
    if (error.message) {
      message = error.message;
    }
    alert = (
      <Alert
        message="Error"
        description={message}
        type="error"
        showIcon={true}
        style={{ marginBottom: '12px', whiteSpace: 'pre-wrap' }}
      />
    );
  }
  if (resetSuccess) {
    const message = 'Password reset successfully!';
    alert = (
      <Alert message="Success" description={message} type="success" showIcon={true} style={{ marginBottom: '12px' }} />
    );
  }

  let cardContent = (
    <Spin tip="Checking Link..." size="large">
      <div style={{ height: 150 }} />
    </Spin>
  );

  if (linkVerified !== null) {
    if (linkVerified) {
      cardContent = (
        <>
          <p style={{ textAlign: 'center' }}>Please enter new password and confirm.</p>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password onBlur={validatePassword} />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Confirm Password is required',
              },
              ({ getFieldValue }) => ({
                validator(rule: any, value: string | null) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('The two passwords that you entered do not match');
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <FormItem>
            <Button type="primary" htmlType="submit" size="large" disabled={loading} shape="round" block>
              {loading ? (
                <span>
                  <LoadingOutlined /> Processing...
                </span>
              ) : (
                <span>Reset Password</span>
              )}
            </Button>
          </FormItem>
        </>
      );
    } else {
      cardContent = (
        <FormItem>
          <Button htmlType="button" size="large" type="primary" disabled={loading} shape="round" block>
            <Link to={Routes.generate(Routes.REQUEST_RESET_PASSWORD, {}, { mobileview: urlQuery.mobileview })}>
              Request Password Reset
            </Link>
          </Button>
        </FormItem>
      );
    }
  }

  if (resetSuccess) {
    cardContent = <></>;
  }

  return (
    <LoginLayout cardTitle="Reset Password">
      <div style={{ textAlign: 'center', marginBottom: '15px' }}>
        <PageTitle title="Reset Password" hide={true} />
      </div>

      <Row justify="center">
        <Col xs={24} sm={18}>
          <Form layout="vertical" onFinish={handleSubmit} requiredMark={false}>
            {alert}
            {cardContent}
            <FormItem>
              <Button htmlType="button" size="large" shape="round" block ghost>
                <Link to={Routes.generate(Routes.LOGIN, {}, { mobileview: urlQuery.mobileview })}>Go to Login</Link>
              </Button>
            </FormItem>
          </Form>
        </Col>
      </Row>
    </LoginLayout>
  );
};

export default ResetPasswordPage;
