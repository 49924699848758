import { notification } from 'antd';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import React, { useState } from 'react';
import TableRequestDTO from '../../models/TableRequestDTO';
import TableResponseDTO from '../../models/TableResponseDTO';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import NominationSearchFormDTO from '../../models/NominationSearchFormDTO';
import NominationSearchApiService from '../../api/NominationSearchApiService';
import NominationListDTO from '../../models/NominationListDTO';
import useHasPermission from '../../hooks/useHasPermission';
import Permission from '../../consts/Permission';
import { AcceptedFormats } from '../shared/DatePicker2';

interface NominationSearchListProps {
  nominationSearchDTO: NominationSearchFormDTO;
  openDetails: (claimId: string) => void;
}

const NominationSearchList = (props: NominationSearchListProps) => {
  const hasPermission = useHasPermission();
  const [loading, setLoading] = useState(false);
  const [pnfs, setPnfs] = useState<NominationListDTO[]>([]);

  const nominationSearchDataTableRef = React.createRef<DataTable<any>>();

  const tableColumns = (): DataTableColumnProps<NominationListDTO>[] => {
    const columns: DataTableColumnProps<NominationListDTO>[] = [
      {
        title: 'Provider Nomination ID',
        dataIndex: 'referenceNumber',
        defaultSortOrder: 'ascend',
        sorter: {},
      },
      {
        title: 'Date Submitted',
        dataIndex: 'submittedOn',
        defaultSortOrder: 'ascend',
        sorter: {},
        renderDataTransform: (value: any) => (value != null ? moment(value).format(AcceptedFormats.at(0)) : null),
      },
      {
        title: 'Patient Name',
        dataIndex: 'patientName',
        defaultSortOrder: 'ascend',
        sorter: {},
      },
      {
        title: 'Provider Name',
        dataIndex: 'providerName',
        defaultSortOrder: 'ascend',
        sorter: {},
      },
      {
        title: 'Facility Name',
        dataIndex: 'facilityName',
        defaultSortOrder: 'ascend',
        sorter: {},
      },
    ];

    if (hasPermission(Permission.DOCUMENTS_PNF_DISPLAYSTATUS)) {
      columns.push({
        title: 'Status',
        dataIndex: 'processingStatus',
        sorter: {},
      });
    }

    //TODO CT: Hiding for future use
    // columns.push(      {
    //   title: '',
    //   dataIndex: '',
    //   render: (rowData: NominationListDTO) => (
    //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
    //     <Button shape="round" ghost onClick={(e) => props.openDetails(rowData.id ?? '')}>
    //       Details
    //     </Button>
    //   ),
    // })

    return columns;
  };

  const fetchTableData = (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<NominationListDTO>) => void
  ) => {
    const fromDate = props.nominationSearchDTO.fromDate
      ? moment(props.nominationSearchDTO.fromDate)?.toDate().toDateString()
      : '';
    const toDate = props.nominationSearchDTO.toDate
      ? moment(props.nominationSearchDTO.toDate)?.toDate().toDateString()
      : '';
    setLoading(true);
    NominationSearchApiService.getMemberNominationList(
      requestState,
      props.nominationSearchDTO.clientId,
      fromDate,
      toDate,
      props.nominationSearchDTO.lastName ?? ''
    )
      .then((results) => {
        if (!checkEcho()) {
          return;
        }
        setPnfs(results.results || []);
        setLoading(false);
        callback(results);
      })
      .catch((error: any) => {
        setLoading(false);
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  };

  return (
    <>
      <Title level={3}>Results</Title>
      <DataTable
        ref={nominationSearchDataTableRef}
        serverSide={true}
        globalSearch={true}
        globalSearchPlaceholder="Search Nominations"
        tableProps={{
          rowKey: 'id',
          loading: loading,
        }}
        columns={tableColumns()}
        data={pnfs}
        fetchData={fetchTableData}
        styleOptions={{
          alternatingRowHighlight: true,
        }}
      />
    </>
  );
};

export default NominationSearchList;
