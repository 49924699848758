import { Menu } from 'antd';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthenticationContext } from '../auth/AuthenticationContext';
import AccessRole from '../consts/AccessRole';
import MailroomDirections from '../consts/MailroomDirections';
import Permission from '../consts/Permission';
import Reports from '../consts/Reports';
import Routes from '../consts/Routes';
import useHasPermission from '../hooks/useHasPermission';
import HistoryUtil from '../utils/HistoryUtil';
const { SubMenu } = Menu;

interface SideNavProps {
  menuCollapsed: boolean;
  isMobile?: boolean;
}

export declare type MenuItemType = {
  key: string;
  title?: string | React.ReactNode;
  url?: string;
  // A function in case user permissions / roles change without this component unmounting and remounting.
  shouldRender?: () => boolean;
  subMenu?: MenuItemType[];
  newTab?: boolean;
  match?: string;
};

const SideNav = (props: SideNavProps) => {
  const authContext = useContext(AuthenticationContext);
  const hasPermission = useHasPermission();

  const sideMenuItems: MenuItemType[] = [
    {
      key: 'home',
      title: 'Home',
      url: '/',
    },
    {
      key: 'demo',
      title: 'Demo Mode',
      url: Routes.GATE,
      shouldRender: () =>
        hasPermission(Permission.TOOLS_SIDENAV_DEMOMODELINK) && (authContext.user?.multiRole ?? false),
    },
    {
      key: 'clients',
      title: 'Clients',
      url: Routes.CLIENTS_BASE,
      shouldRender: () =>
        [AccessRole.BROKER, AccessRole.CLAIMDOC, AccessRole.TPA].includes(authContext.user?.accessRoleId ?? 0),
    },
    {
      key: 'admin',
      title: 'Admin',
      subMenu: [
        {
          key: 'brokers',
          title: 'Brokers',
          url: Routes.ADMIN_BROKER_LIST,
          shouldRender: () => hasPermission(Permission.ADMIN_BROKER_LIST_VIEW),
        },
        {
          key: 'admin-clients',
          title: 'Clients',
          url: Routes.ADMIN_CLIENTS,
          shouldRender: () => hasPermission(Permission.ADMIN_CLIENT_LIST_VIEW),
        },
        {
          key: 'dpcs',
          title: 'DPCs',
          url: Routes.ADMIN_DPC_LIST,
          shouldRender: () => hasPermission(Permission.ADMIN_DPC_LIST_VIEW),
        },
        {
          key: 'guestaccess',
          title: 'Employer PIN Access',
          url: Routes.ADMIN_GUEST_ACCESS,
          shouldRender: () => hasPermission(Permission.ADMIN_EMPLOYERPINACCESS_VIEW),
        },
        {
          key: 'other-companies',
          title: 'Other Companies',
          url: Routes.ADMIN_OTHERCOMPANY_LIST,
          shouldRender: () => hasPermission(Permission.ADMIN_OTHERCOMPANY_LIST_VIEW),
        },
        {
          key: 'permissiongroups',
          title: 'Permission Groups',
          url: Routes.generate(Routes.ADMIN_SECURITY_PERMISSION_GROUPS),
          shouldRender: () => hasPermission(Permission.ADMIN_PERMISSIONGROUPS_LIST_VIEW),
        },
        {
          key: 'admin-providers',
          title: 'Providers',
          url: Routes.generate(Routes.ADMIN_PROVIDER_LIST),
          shouldRender: () => hasPermission(Permission.ADMIN_PROVIDER_LIST_VIEW),
        },
        {
          key: 'tpas',
          title: 'TPAs',
          url: Routes.ADMIN_TPA_LIST,
          shouldRender: () => hasPermission(Permission.ADMIN_TPA_LIST_VIEW),
        },
        {
          key: 'users',
          title: 'Users',
          url: Routes.generate(Routes.ADMIN_USER_LIST, { type: 'all' }),
          shouldRender: () => hasPermission(Permission.ADMIN_USERS_LIST_VIEW),
          match: '/admin/users',
        },
      ],
    },
    {
      key: 'education',
      title: 'Education',
      url: Routes.generate(Routes.EDUCATION_BASE),
      shouldRender: () => hasPermission(Permission.GENERAL_EDUCATION_ACCESS),
    },
    {
      key: 'reports',
      title: 'Reports',
      url: Routes.REPORTS_BASE,
      subMenu: [
        {
          key: 'reports-claimanalysis',
          title: 'Claim Analysis',
          url: Routes.generate(Routes.REPORTS_POWERBI, { reportName: Reports.CLAIM_ANALYSIS }),
          shouldRender: () => hasPermission(Permission.REPORTING_ACCESS_COSTANALYSISREPORT),
        },
        {
          key: 'reports-performance',
          title: 'Performance',
          url: Routes.generate(Routes.REPORTS_POWERBI, { reportName: Reports.PERFORMANCE }),
          shouldRender: () => hasPermission(Permission.REPORTING_ACCESS_PERFORMANCEREPORT),
        },
      ],
    },
    {
      key: 'support',
      title: 'Support',
      subMenu: [
        {
          key: 'failed-registrations',
          title: 'Failed Registrations',
          url: Routes.SUPPORT_FAILED_MEMBER_REGISTRATIONS,
          shouldRender: () => hasPermission(Permission.SUPPORT_ACTIONLOGS_FAILEDMEMBERREGISTRATIONS),
        },
        {
          key: 'member-search',
          title: 'Client Member Search',
          url: Routes.SUPPORT_MEMBER_SEARCH,
          shouldRender: () => hasPermission(Permission.SUPPORT_CLIENTMEMBERSEARCH_BASEACCESS),
        },
      ],
    },
    {
      key: 'tools',
      title: 'Tools',
      subMenu: [
        {
          key: 'pave-the-way',
          title: 'Pave the Way',
          url: Routes.PAVE_THE_WAY,
          shouldRender: () => hasPermission(Permission.TOOLS_PAVETHEWAY_ACCESS),
        },
        {
          key: 'providers',
          title: 'Provider Directory',
          url: Routes.PROVIDER_DIRECTORY,
          shouldRender: () => hasPermission(Permission.TOOLS_PROVIDERDIRECTORY_MAPVIEWBASE),
        },
        {
          key: 'providernomination',
          title: 'Provider Nomination',
          url: Routes.PROVIDER_NOMINATION,
          shouldRender: () => hasPermission(Permission.TOOLS_PROVIDERNOMINATION_ACCESS),
        },
        {
          key: 'nominationsearch',
          title: 'Nomination Search',
          url: Routes.NOMINATION_SEARCH,
          shouldRender: () => hasPermission(Permission.TOOLS_NOMINATIONSEARCH_ACCESS),
        },
        {
          key: 'member-cards',
          title: 'Member Cards',
          url: Routes.MEMBER_CARDS,
          shouldRender: () => hasPermission(Permission.TOOLS_MEMBERCARD_ACCESS),
        },
        {
          key: 'pricing-tool',
          title: 'Pricing Tool',
          url: Routes.PRICING_TOOL,
          shouldRender: () => hasPermission(Permission.TOOLS_PRICINGTOOL_ACCESS),
        },
        {
          key: 'claimsearch',
          title: 'Claim Search',
          url: Routes.CLAIM_SEARCH,
          shouldRender: () => hasPermission(Permission.TOOLS_CLAIMSEARCH_ACCESS),
        },
        {
          key: 'balancebill',
          title: 'Submit Medical Bill',
          url: Routes.BALANCE_BILL,
          shouldRender: () => hasPermission(Permission.TOOLS_BALANCEBILL_ACCESS),
        },
      ],
    },
    {
      key: 'documents',
      title: 'Documents',
      url: Routes.DOCUMENTS_BASE,
      shouldRender: () => hasPermission(Permission.DOCUMENTS_SIDENAV_ACCESS),
    },
    {
      key: 'utilities',
      title: 'Utilities',
      subMenu: [
        {
          key: 'mailroom-files',
          title: 'Mailroom Files',
          url: Routes.generate(Routes.MAILROOM_FILE_LIST, { tab: MailroomDirections.INCOMING }),
          shouldRender: () => hasPermission(Permission.UTILITIES_MAILROOM_FILE_VIEW),
          match: '/mailroom/files',
        },
        {
          key: 'mailroom-accounts',
          title: 'Mailroom Accounts',
          url: Routes.MAILROOM_ACCOUNT_LIST,
          shouldRender: () => hasPermission(Permission.UTILITIES_MAILROOM_ACCOUNT_VIEW),
        },
        {
          key: 'doc-scan',
          title: 'DOC Scan',
          url: Routes.DOC_SCAN,
          shouldRender: () => hasPermission(Permission.UTILITIES_DOCSCAN_ACCESS),
        },
      ],
    },
  ];

  const getSelectedKeysForNav = (menu: MenuItemType[], path: string): string[] => {
    let selectedKeys: string[] = [];
    menu.forEach((s) => {
      if (s.key === 'home' && path == s.url) {
        selectedKeys.push(s.key);
      }
      if (s.subMenu) {
        selectedKeys = selectedKeys.concat(getSelectedKeysForNav(s.subMenu, path));
      } else if (s.key !== 'home' && s.url && path.indexOf(s.match ?? s.url) >= 0) {
        selectedKeys.push(s.key);
      }
    });
    return selectedKeys;
  };

  const getOpenKeysForNav = (menu: MenuItemType[], path: string): string[] => {
    const selectedKeys: string[] = [];
    menu.forEach((s) => {
      if (s.subMenu) {
        if (getSelectedKeysForNav(s.subMenu, path).length > 0) {
          selectedKeys.push(s.key);
        }
      }
    });
    return selectedKeys;
  };

  const [selectedKeys, setSelectedKeys] = useState<string[]>(
    getSelectedKeysForNav(sideMenuItems, HistoryUtil.location.pathname)
  );
  const [openKeys, setOpenKeys] = useState<string[]>(getOpenKeysForNav(sideMenuItems, HistoryUtil.location.pathname));

  useEffect(() => {
    HistoryUtil.listen((location) => {
      setSelectedKeys(getSelectedKeysForNav(sideMenuItems, location.pathname));
      setOpenKeys(getOpenKeysForNav(sideMenuItems, HistoryUtil.location.pathname));
    });
  }, []);

  const renderDefaultMenu = (item: MenuItemType, depth: number) => {
    if ((item.shouldRender && item.shouldRender() === true) || item.shouldRender === undefined) {
      if (item.subMenu) {
        const subMenu = item.subMenu.map((s) => renderDefaultMenu(s, depth + 1)).filter((e) => !!e);
        if (subMenu.length > 0) {
          return (
            <SubMenu key={item.key} title={item.title} style={{ marginLeft: 5 * depth }}>
              {subMenu}
            </SubMenu>
          );
        }
        return null;
      } else {
        return (
          <Menu.Item key={item.key}>
            <NavLink to={{ pathname: item.url ?? '' }} target={item.newTab ? '_blank' : undefined}>
              <span style={{ marginLeft: 5 * depth }}>{item.title}</span>
            </NavLink>
          </Menu.Item>
        );
      }
    }
    return null;
  };

  const onOpenSubMenuChange = (keys: React.Key[]) => {
    if (!props.menuCollapsed) {
      setOpenKeys(keys as string[]);
    }
  };

  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={['home']}
      selectedKeys={selectedKeys}
      openKeys={openKeys}
      onOpenChange={onOpenSubMenuChange}
    >
      {sideMenuItems.map((m) => renderDefaultMenu(m, 0))}
    </Menu>
  );
};

export default SideNav;
