import { CheckCircleFilled } from '@ant-design/icons';
import { Alert, Button, Col, Divider, Form, Input, Modal, Row, Select, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import AccountApiService from '../../api/AccountApiService';
import { AuthenticationContext } from '../../auth/AuthenticationContext';
import ClaimDocMemberSupport from '../../components/contact/ClaimDocMemberSupport';
import SeparatedDatePicker from '../../components/shared/SeparatedDatePicker';
import AccessRole from '../../consts/AccessRole';
import Routes from '../../consts/Routes';
import useUrlQuery from '../../hooks/useUrlQuery';
import RegisterDTO from '../../models/RegisterDTO';
import RegisterResultDTO from '../../models/RegisterResultDTO';
import ResultDTO from '../../models/ResultDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import LoginLayout from './LoginLayout';

const FormItem = Form.Item;

interface RegisterPageProps {
  code?: string;
  type?: string;
  email?: string;
  role?: string;
  mobileview?: string;
}

const RegisterPage = () => {
  const [accountSubmitted, setAccountSubmitted] = useState(false);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [showMemberRegistration, setShowMemberRegistration] = useState(false);
  const [form] = Form.useForm();
  const [urlQuery] = useUrlQuery<RegisterPageProps>();
  const [fromRedirect, setFromRedirect] = useState(false);
  const [roleId, setRoleId] = useState<number | undefined>();
  const [checkEmail, setCheckEmail] = useState(false);

  const authContext = useContext(AuthenticationContext);

  useEffect(() => {
    if (urlQuery.code && urlQuery.type && urlQuery.email) {
      setLoading(true);
      AccountApiService.registerCallback(urlQuery.code, urlQuery.type, urlQuery.email)
        .then((res) => {
          if (res?.redirectToLogin ?? false) {
            HistoryUtil.replace(Routes.generate(Routes.LOGIN, {}, { mobileview: urlQuery.mobileview }));
          }
          if (res?.requestedRoleId) {
            setFromRedirect(true);
            setRoleId(res.requestedRoleId);

            form.setFieldsValue({
              requestedRoleId: res.requestedRoleId,
              email: urlQuery.email,
            });

            if (res.requestedRoleId === AccessRole.MEMBER) {
              setShowMemberRegistration(true);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (urlQuery.role && urlQuery.email) {
      form.setFieldsValue({
        requestedRoleId: parseInt(urlQuery.role),
        email: urlQuery.email,
      });

      if (parseInt(urlQuery.role) === AccessRole.MEMBER) {
        setShowMemberRegistration(true);
      }
    }

    if (authContext.user) {
      authContext.logout();
    }
  }, []);

  const goToLogin = () => {
    HistoryUtil.push(Routes.generate(Routes.LOGIN, {}, { mobileview: urlQuery.mobileview }));
  };

  const showMemberHelpModal = () => {
    Modal.error({
      title: 'Member Registration Error',
      content: (
        <>
          We ran into an issue finding you in our system.
          <br />
          Please contact your ClaimDOC Member Advocate for assistance.
          <br />
          <ClaimDocMemberSupport />
        </>
      ),
      width: 500,
      okButtonProps: { shape: 'round' },
    });
  };

  const handleSubmit = () => {
    setLoading(true);

    const model = form.getFieldsValue() as RegisterDTO;

    if (roleId) {
      model.requestedRoleId = roleId;
      if (roleId == AccessRole.MEMBER) {
        setShowMemberRegistration(true);
      }
      model.email = urlQuery.email ?? '';
      model.code = urlQuery.code ?? '';
    }

    AccountApiService.register(model)
      .then((registerResponse: RegisterResultDTO | null) => {
        if (registerResponse?.succeeded) {
          setCheckEmail(registerResponse.requireConfirmedAccount);
          setAccountSubmitted(true);
          setLoading(false);
        } else {
          if (registerResponse?.showMemberRegistrationHelp) {
            showMemberHelpModal();
            setError(null);
          } else {
            setError({ message: registerResponse?.errors.join('\n') });
          }
          setLoading(false);
        }
      })
      .catch((results: any) => {
        setError(results);
        setLoading(false);
      });
  };

  const validatePassword = (e: any) => {
    if (e.target.value !== null && e.target.value !== '') {
      AccountApiService.validatePassword(e.target.value).then((response: ResultDTO | null) => {
        if (response?.succeeded) {
          setError(null);
        } else {
          setError({ message: response?.errors.join('\n') });
        }
      });
    }
  };

  let alert: JSX.Element | null = null;

  if (error !== null) {
    let message = 'An error occurred while trying to register new user.';
    if (error && error.message) {
      message = error.message;
    }
    alert = (
      <Alert
        message="Error"
        description={message}
        type="error"
        showIcon={true}
        style={{ marginBottom: '12px', whiteSpace: 'pre-wrap' }}
      />
    );
  }

  return (
    <LoginLayout
      cardTitle={!accountSubmitted ? 'Create a ClaimDOC Account' : ''}
      prompt={
        !accountSubmitted
          ? fromRedirect
            ? 'Please enter the following information to complete your account.'
            : 'Please enter the email associated with your account.'
          : ''
      }
    >
      {!accountSubmitted ? (
        <>
          {alert}
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Row>
              <Col xs={0} md={3}>
                {/* Empty */}
              </Col>
              <Col xs={24} md={18}>
                <>
                  <FormItem
                    name="requestedRoleId"
                    label={<div>Are you a member, employer, TPA, broker?</div>}
                    rules={[{ required: true, message: 'User role is required' }]}
                    style={{ textAlign: 'left' }}
                  >
                    <Select
                      placeholder="I am a"
                      style={{ width: '100%' }}
                      disabled={loading}
                      open={fromRedirect ? false : undefined}
                      onChange={(value) => {
                        setShowMemberRegistration(value === AccessRole.MEMBER);
                      }}
                    >
                      <Select.Option value={AccessRole.MEMBER} title="Member">
                        Member
                      </Select.Option>
                      <Select.Option value={AccessRole.EMPLOYER} title="Employer">
                        Employer
                      </Select.Option>
                      <Select.Option value={AccessRole.TPA} title="TPA">
                        TPA
                      </Select.Option>
                      <Select.Option value={AccessRole.BROKER} title="Broker">
                        Broker
                      </Select.Option>
                      <Select.Option value={AccessRole.DPC} title="DPC">
                        DPC
                      </Select.Option>
                      <Select.Option value={AccessRole.OTHERCOMPANY} title="Other">
                        Other
                      </Select.Option>
                    </Select>
                  </FormItem>
                  <FormItem
                    name="email"
                    label="Email Address"
                    rules={[
                      {
                        required: true,
                        message: 'A valid email is required.',
                        type: 'email',
                      },
                    ]}
                  >
                    <Input disabled={loading} readOnly={fromRedirect} />
                  </FormItem>
                </>

                {showMemberRegistration ? (
                  <>
                    <FormItem
                      name="lastName"
                      label="Last Name"
                      rules={[
                        {
                          required: true,
                          message: 'Required.',
                        },
                      ]}
                    >
                      <Input disabled={loading} />
                    </FormItem>
                    <FormItem
                      name="last4SSN"
                      label="Last 4 of SSN"
                      rules={[
                        {
                          required: true,
                          len: 4,
                          message: 'Must be 4 digits.',
                        },
                      ]}
                    >
                      <Input maxLength={4} disabled={loading} />
                    </FormItem>
                    <FormItem
                      name="last4HealthCardId"
                      label="Last 4 Digits of Member ID on Medical Card (use prior carrier card if ClaimDOC card not received)"
                      rules={[
                        {
                          required: true,
                          len: 4,
                          message: 'Must be 4 digits.',
                        },
                      ]}
                    >
                      <Input maxLength={4} disabled={loading} />
                    </FormItem>
                    <FormItem
                      name="dob"
                      label="Date of Birth"
                      rules={[
                        {
                          required: true,
                          type: 'date',
                          message: 'Required.',
                        },
                      ]}
                    >
                      <SeparatedDatePicker disabled={loading} placeholder />
                    </FormItem>
                  </>
                ) : null}

                <FormItem
                  name="phoneNumber"
                  label="Phone Number"
                  rules={[
                    {
                      pattern: /\d{10}$/,
                      message: 'Please enter a valid 10 digit phone number.',
                    },
                  ]}
                >
                  <Input type="tel" maxLength={10} disabled={loading} />
                </FormItem>

                <FormItem name="password" label="Password" rules={[{ required: true, message: 'Required' }]}>
                  <Input.Password onBlur={validatePassword} disabled={loading} />
                </FormItem>
                <FormItem
                  name="confirmPassword"
                  label="Confirm Password"
                  rules={[
                    {
                      required: true,
                      message: 'Required.',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule: any, value: string | null) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject('The two passwords that you entered do not match');
                      },
                    }),
                  ]}
                >
                  <Input.Password disabled={loading} />
                </FormItem>
                <FormItem>
                  <Button htmlType="submit" size="large" type="primary" loading={loading} shape="round" block>
                    {loading ? 'Creating Account...' : 'Create Account'}
                  </Button>
                </FormItem>
              </Col>
            </Row>
          </Form>
          <Divider />
          <span>
            <Typography.Title level={3} style={{ textAlign: 'center' }}>
              Already have an account?
            </Typography.Title>
            <Row>
              <Col xs={0} md={3}>
                {/* Empty */}
              </Col>
              <Col xs={24} md={18}>
                <Form>
                  <FormItem>
                    <Button onClick={goToLogin} htmlType="button" size="large" shape="round" block ghost>
                      Login
                    </Button>
                  </FormItem>
                </Form>
              </Col>
            </Row>
          </span>
        </>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <CheckCircleFilled style={{ fontSize: '64px', color: '#52c41a' }} />
          {checkEmail ? (
            <>
              <Typography.Title level={2}>Confirm your email</Typography.Title>

              <Row justify="center">
                <Col xs={24} sm={18}>
                  <p>
                    An email titled <b>ClaimDOC - Confirm your email</b> has been sent from{' '}
                    <b>claimdocportalnotification@claim-doc.com</b> to the email address you provided during
                    registration. You should receive this email within 10 minutes. Click the link provided in the email
                    to complete registration.
                  </p>
                  <Button onClick={goToLogin} htmlType="button" size="large" shape="round" type="primary" block>
                    Back to Login
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Typography.Title level={2}>Account Registration Complete</Typography.Title>

              <Row justify="center">
                <div style={{ paddingBottom: '20px' }}>
                  Any account notifications will be sent from <b>claimdocportalnotification@claim-doc.com</b> to the
                  email address you provided during registration.
                </div>
                <Col xs={24} sm={18}>
                  <Button onClick={goToLogin} htmlType="button" size="large" shape="round" type="primary" block>
                    Back to Login
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </div>
      )}
    </LoginLayout>
  );
};

export default RegisterPage;
